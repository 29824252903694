<template>
  <ConsentManagerInitialModal
    v-if="isInitialModalVisible && !hideConsentInfo"
  />

  <LazyConsentManagerSettingsModal
    v-if="isSettingsModalVisible && !hideConsentInfo"
  />
</template>

<script setup lang="ts">
const { overrideCreateElement, isSettingsModalVisible, isInitialModalVisible } =
  useConsentManager()
const route = useRoute()

const hideConsentInfo = computed(
  () => route.query['hide-consent-info'] || route.query['theme-roller']
)

if (import.meta.client) {
  overrideCreateElement()
}

defineOptions({
  name: 'ConsentManager',
})
</script>
