import DOMPurify from 'dompurify'

type SanitizeFunction = (html: string, config?: DOMPurify.Config) => string

export default defineNuxtPlugin(async (nuxtApp) => {
  let sanitize: SanitizeFunction

  if (import.meta.server) {
    const { JSDOM } = await import('jsdom')
    sanitize = DOMPurify(new JSDOM('').window).sanitize
  } else {
    sanitize = DOMPurify.sanitize
  }

  nuxtApp.provide('sanitizeHTML', sanitize)
})
