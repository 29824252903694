<template>
  <transition
    :enter-from-class="enterFromClasses"
    enter-active-class="ease-in-out duration-300"
    :enter-to-class="enterToClasses"
    leave-from-class="opacity-100"
    leave-active-class="ease-in-out duration-300"
    :leave-to-class="leaveToClasses"
    appear
  >
    <slot />
  </transition>
</template>

<script setup lang="ts">
import type { TransitionPositions } from '@base/types/constants/transition-positions'

interface Props {
  enterFrom: TransitionPositions
  enterTo: TransitionPositions
  leaveTo: TransitionPositions
  withFade?: boolean
  active?: boolean
}

const {
  enterFrom,
  enterTo,
  leaveTo,
  withFade = false,
  active = true,
} = defineProps<Props>()

defineOptions({
  name: 'TransitionSlide',
})

const { enterFromClasses, enterToClasses, leaveToClasses } =
  useTransitionPositions({
    enterFrom: enterFrom,
    enterTo: enterTo,
    leaveTo: leaveTo,
    withFade: withFade,
  })
</script>
