import revive_payload_client_hNUhnFRR9m from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vTMomwIFbF from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xRI8ryEEgO from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_CqKXMdrUAK from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XxJmBbP1Eb from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5410uMGMeD from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_09Z46Oh4Sk from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/apps/site-factory/.nuxt/components.plugin.mjs";
import prefetch_client_dfLkBIkncZ from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_UVXR3ae1vH from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/@nuxt+icon@1.8.2_vite@5.4.3_vue@3.4.38/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/apps/site-factory/.nuxt/formkitPlugin.mjs";
import plugin_jVCYKVgYzL from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@types+node@22.10.1_graphql-tag@2.12.6_typescript@5.5.4/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import composition_GTxKeb5Qzl from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_vue-router@4.4.3_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_FgLpto0kRT from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_vue-router@4.4.3_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/sitefactory/live/2024-12-11_17-39-57_2362790/layers/base/plugins/sentry.client.ts";
export default [
  revive_payload_client_hNUhnFRR9m,
  unhead_vTMomwIFbF,
  router_xRI8ryEEgO,
  payload_client_CqKXMdrUAK,
  navigation_repaint_client_XxJmBbP1Eb,
  check_outdated_build_client_5410uMGMeD,
  chunk_reload_client_09Z46Oh4Sk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dfLkBIkncZ,
  plugin_UVXR3ae1vH,
  formkitPlugin_pZqjah0RUG,
  plugin_jVCYKVgYzL,
  composition_GTxKeb5Qzl,
  i18n_FgLpto0kRT,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV
]