<template>
  <div>
    <div :class="isMinimized ? cssClasses.content : ''" v-html="text" />
    <Button
      v-if="toggable || isMinimized"
      :theme="BUTTON_THEME.TRANSPARENT"
      :title="isMinimized ? t('btn.show') : t('btn.hide')"
      :text="isMinimized ? t('btn.show') : t('btn.hide')"
      :class="cssClasses.button"
      @click="isMinimized = !isMinimized"
    />
  </div>
</template>

<script setup lang="ts">
interface Props {
  text: string
  toggable?: boolean
  classes?: {
    minimizedText?: string
    button?: string
  }
}

// @TODO: https://www.youtube.com/watch?v=B_n4YONte5A implement this

const { text, toggable } = defineProps<Props>()

const isMinimized = ref(true)

const cssClasses = computed(() => ({
  content: 'line-clamp-2 md:line-clamp-none',
  button:
    'md:hidden [&>span]:cursor-pointer [&>span]:underline pl-0 place-self-start',
}))

const { t } = useI18n()
defineOptions({
  name: 'ExpandableText',
})
</script>

<i18n>
de:
  btn:
    show: "Mehr anzeigen"
    hide: "Weniger anzeigen"
    more: "Mehr"
es:
  btn:
    show: "Mostrar más"
    hide: "Mostrar menos"
    more: "Más"
</i18n>
