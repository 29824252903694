interface UserAuthState {
  accessToken: string | null
  refreshToken: string | null
}

export default function useUserAuth() {
  const state = useState<UserAuthState>('auth-token-state', () => ({
    accessToken: null,
    refreshToken: null,
  }))

  const isLoggedIn = computed(() => {
    return !!state.value.accessToken && !!state.value.refreshToken
  })

  function logout() {
    const accessToken = useCookie(ACCOUNT_ACCESS_TOKEN_COOKIE_NAME)
    const refreshToken = useCookie(ACCOUNT_REFRESH_TOKEN_COOKIE_NAME)

    useGqlToken(null)
    accessToken.value = null
    refreshToken.value = null
    state.value.accessToken = null
    state.value.refreshToken = null
  }

  return {
    state,
    isLoggedIn,
    logout,
  }
}
